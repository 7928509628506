<template>
  <div>
    <PageHeader />
    <b-overlay :show="isLoading">
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <h6>
            {{ `${$t('fields.result')} (${playerList.length})` }}
          </h6>
          <label class="d-inline-flex align-items-center">
            <b-input-group>
              <b-form-input
                v-model="filterPlayerCodeName"
                type="search"
                class="mx-1"
                :placeholder="
                  `${$t('fields.search')}... (${$t('user.prefix')})`
                "
              ></b-form-input>
            </b-input-group>
            <b-input-group>
              <b-form-input
                v-model="filterPlayerFirstname"
                type="search"
                class="mx-1"
                :placeholder="
                  `${$t('fields.search')}... (${$t('user.firstname')})`
                "
              ></b-form-input>
            </b-input-group>
            <b-input-group>
              <b-form-input
                v-model="filterPlayerLastname"
                type="search"
                class="mx-1"
                :placeholder="
                  `${$t('fields.search')}... (${$t('user.lastname')})`
                "
              ></b-form-input>
            </b-input-group>
            <b-input-group>
              <b-form-input
                v-model="filterPlayerUsername"
                type="search"
                :placeholder="
                  `${$t('fields.search')}... (${$t('user.username')})`
                "
              ></b-form-input>
            </b-input-group>
            <FilterAgentsDropdown
              field-name="id"
              @update="(val) => (filterAgent = val)"
            />
          </label>
        </div>
        <b-table
          :items="playerList"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(username)="data">
            <b-link :to="`/players/detail/${data.item.id}`">
              {{ data.value }}
            </b-link>
          </template>

          <template #cell(agent_id)="data">
            <span v-if="data.item.agent_username">
              {{ data.item.agent_username }}
            </span>
            <b-badge
              v-if="!data.value"
              variant="danger"
            >
              <i class="uil uil-times-circle"></i>
              {{ $t('agent.no_agent') }}
            </b-badge>
          </template>
          <template #cell(firstname)="data">
            {{ data.value }}
            {{ data.item.lastname }}
          </template>
          <template #cell(hold_balance)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(credit_free_status)="data">
            <b-badge
              v-if="data.value === 0"
              variant="success"
              class="ml-2"
            >
              Applied
            </b-badge>
            <b-badge
              v-if="data.value === 1"
              variant="primary"
              class="ml-2"
            >
              CLEARED
            </b-badge>
            <b-badge
              v-if="data.value === 2"
              variant="danger"
              class="ml-2"
            >
              REVOKED
            </b-badge>
            <b-badge
              v-if="data.value === 3"
              variant="light"
              class="ml-2"
            >
              NONE
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(note)="data">
            <span v-if="data.value.length === 0">-</span>
            <b-badge v-else>{{ data.value }}</b-badge>
          </template>
          <template #cell(id)="data">
            <b-dropdown
              variant="info"
              size="sm"
              dropleft
            >
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item @click="toggleCreateFreeCredit(data.item)">
                <feather
                  type="plus-circle"
                  class="icon-xs text-success"
                ></feather>
                <span class="pl-2 text-success">
                  ให้ฟรีเครดิต
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeFreeCredit(data.item)">
                <feather
                  type="plus-circle"
                  class="icon-xs text-danger"
                ></feather>
                <span class="pl-2 text-danger">
                  ยกเลิกฟรีเครดิต
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="toggleRemarkModal(data.item)">
                <i class="uil uil-comment-info mr-2"></i>
                <span>
                  {{ $t('fields.remark') }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
          </template>
        </b-table>
      <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
      </b-card>
    </b-overlay>
    <FreeCreditModal
      ref="modal"
      :player="selectedPlayer"
      @created="fetchData()"
    />
    <b-modal
      v-model="isRemarkModalActive"
      :title="$t('fields.remark')"
      hide-footer
    >
      <RemarkForm
        :value="selectedPlayer"
        :is-loading="player.isUpdatingPlayer"
        @submit="onAddRemark"
        @cancel="closeRemarkModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import PromotionService from '../../../services/promotion'

export default {
  name: 'FreeCredit',
  components: {
    FilterAgentsDropdown: () =>
      import('../../../components/agents/filter-agents-dropdown'),
    FreeCreditModal: () =>
      import('../../../components/credits/free-credit-modal'),
    RemarkForm: () => import('../../../components/forms/remark-form'),
  },
  data() {
    return {
      isRemarkModalActive: false,
      isLoading: false,
      currentPage: 1,
      limit: 50,
      onSearchTimeout: null,
      fields: [
        '#',
        {
          key: 'agent_id',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'code_name',
          label: this.$t('user.prefix'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'username',
          label: this.$t('user.username'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'firstname',
          label: `${this.$t('user.firstname')} ${this.$t('user.lastname')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'note',
          label: this.$t('user.note'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'phone_number',
          label: this.$t('user.phone'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'created_at',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'credit_free_status',
          label: this.$t('fields.status'),
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
      selectedAgent: '',
      selectedPlayer: null,
      filterAgent: '',
      filterPlayerUsername: '',
      filterPlayerFirstname: '',
      filterPlayerLastname: '',
      filterPlayerCodeName: '',
    }
  },
  computed: {
    ...mapState(['player']),
    ...mapGetters(['players']),
    playerList() {
      const { data } = this.players
      return data || this.players
    },
    totalRow() {
      return this.players.total
    },
  },
  watch: {
    filterAgent() {
      this.fetchData()
    },
    currentPage(val) {
      const nextPage = val - 1
      this.offset = nextPage * this.limit + 1
      this.fetchData()
    },
    filterPlayerUsername() {
      this.delaySearch()
    },
    filterPlayerFirstname() {
      this.delaySearch()
    },
    filterPlayerLastname() {
      this.delaySearch()
    },
    filterPlayerCodeName() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayers', 'updatePlayer']),
    fetchData() {
      this.fetchPlayers({
        limit: this.limit,
        offset: this.currentPage - 1,
        agent_id: this.filterAgent,
        username: this.filterPlayerUsername,
        firstname: this.filterPlayerFirstname,
        lastname: this.filterPlayerLastname,
        code_name: this.filterPlayerCodeName,
      })
    },
    async removeFreeCredit(data) {
      this.isLoading = true
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            return PromotionService.deleteFreeCredit({
              userId: data.id,
              agentId: data.agent_id,
              username: data.username,
            })
          }
        })
        .then(() => {
          this.fetchData()
          this.$store.dispatch('notifySuccess', 'removed')
        })
        .catch((err) => this.$store.dispatch('notifyError', err))
        .finally(() => {
          this.isLoading = false
        })
    },
    toggleCreateFreeCredit(data) {
      this.$refs.modal.toggleModal()
      this.selectedPlayer = data
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    toggleRemarkModal(player) {
      this.isRemarkModalActive = !this.isRemarkModalActive
      this.selectedPlayer = player
    },
    closeRemarkModal() {
      this.isRemarkModalActive = false
      this.selectedPlayer = null
    },
    async onAddRemark(form) {
      // add remark
      await this.updatePlayer({ id: this.selectedPlayer.id, note: form.note })
      this.fetchData()
      // then close modal
      this.closeRemarkModal()
    },
  },
}
</script>

<style scoped></style>
